import { DailyWeatherListItem } from '../DailyWeatherListItem/DailyWeatherListItem';
import { IForecastDayInterval } from '../../model/forecast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import './DailyWeatherList.scss';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

interface IProps {
  warnings: IWarning[][];
  location: ILocation;
  forecastDayIntervals: IForecastDayInterval[];
}

export function DailyWeatherList(props: IProps) {
  const { location, warnings, forecastDayIntervals } = props;
  const translate = useTranslate();

  return (
    <div className="daily-weather-list escape-layout-container-padding">
      <div className="daily-weather-list__headers" aria-hidden={true}>
        <Text as="div" size="5" color="secondary" className="daily-weather-list__symbols-headers">
          <span className="daily-weather-list__header daily-weather-list__header--night">
            {translate('dailyWeatherList/headings/night')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--morning">
            {translate('dailyWeatherList/headings/morning')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--day">
            {translate('dailyWeatherList/headings/day')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--afternoon">
            {translate('dailyWeatherList/headings/afternoon')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--evening">
            {translate('dailyWeatherList/headings/evening')}
          </span>
        </Text>
        <Text as="div" size="5" color="secondary" className="daily-weather-list__forecast-headers">
          <span className="daily-weather-list__header daily-weather-list__header--temperature">
            {translate('dailyWeatherList/headings/temperature/short')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--wind">
            {translate('dailyWeatherList/headings/wind/short')}
          </span>
          <span className="daily-weather-list__header daily-weather-list__header--precipitation">
            {translate('dailyWeatherList/headings/precipitation/short')}
          </span>
        </Text>
      </div>
      <ol className="daily-weather-list__intervals">
        {forecastDayIntervals?.map((dayInterval, index) => (
          <DailyWeatherListItem
            key={index}
            forecastDayInterval={dayInterval}
            index={index}
            location={location}
            warnings={warnings[index]}
          />
        ))}
      </ol>
    </div>
  );
}

export default DailyWeatherList;
