import classNames from 'classnames';
import { Temperature } from '../Temperature/Temperature';
import './MinMaxTemperature.scss';

interface IProps {
  className?: string;
  temperature: { min?: number; max?: number };
}

export const MinMaxTemperature = (props: IProps) => {
  const { min, max } = props.temperature;

  if (min == null || max == null) {
    return null;
  }

  return (
    <span className={classNames('min-max-temperature', props.className)} data-testid="min-max-temperature">
      <Temperature className={'min-max-temperature__max'} value={max} decimal={false} renderScreenReaderLabel={false} />
      <span className="min-max-temperature__separator">/</span>
      <Temperature className={'min-max-temperature__min'} value={min} decimal={false} renderScreenReaderLabel={false} />
    </span>
  );
};
